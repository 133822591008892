import { Divider, Grid, Stack, Typography } from "@mui/material"
import { disableStyle, listHeadingStyle, smallstepNumberContainerStyle } from "app"
import GridContainer from "shared/components/grid-container/grid-container"
import RadioInput from "shared/components/input-fields/radio-input"

const VerificationQuiestions = ({ verificationOnChange, verificationQuestionSet, verificationUpdate }) => {

    return (
        <Stack>
            <Stack>
                <Typography sx={{ ...listHeadingStyle, fontSize: '1rem', textAlign: "left", marginLeft: "15px" }}>
                    {`Verification Questions`}
                </Typography>
                <Divider sx={{ marginTop: "2px" }} />
            </Stack>
            {
                verificationQuestionSet?.map(({ name, label, disabled = false, subCategory }, index) => {
                    const _name = `${name}_${subCategory}`;
                    return (
                        <GridContainer key={index}>
                            <Grid container item xs={10}>
                                <Grid container item xs={2}>
                                    <Stack sx={{ ...smallstepNumberContainerStyle, marginRight: '1px' }}>
                                        <Typography fontWeight={500} sx={{ fontSize: '0.8rem' }}>{index + 1}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid container item xs={10}>
                                    <RadioInput
                                        label={label}
                                        name={_name}
                                        value={verificationUpdate[_name] !== undefined ? verificationUpdate[_name] : null}
                                        onChange={(element) => verificationOnChange(element, index)}
                                        disabled={disabled}
                                        size="small"
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </GridContainer>
                    )
                })
            }
        </Stack>
    )
}

export default VerificationQuiestions