import { FormControl, MenuItem, Select, Typography } from '@mui/material'
import { dropdownMenuItemStyle, inputFieldStyle2, inputFieldStylesx, lable1CopyStyle } from 'app'
import { useTheme } from '@mui/material/styles';
import Label from './label';
const SelectInput = ({ itemList, label, onChange, value, required = false, disabled = false, handleClickOnMenuItem, sx, selectProperty = 'value' }) => {
    const theme = useTheme();
    console.log('itemList12321', itemList, value);

    return (
        <FormControl fullWidth>
            <Label required={required}>{label}</Label>
            <Select
                error={false}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="customeTextField"
                disabled={disabled}
                sx={sx ? sx : inputFieldStylesx}
                onChange={onChange}
                value={value}
            >
                <MenuItem
                    // onMouseEnter={}
                    value={"none"}
                    disabled={true}
                    sx={{
                        cursor: 'not-allowed!important',
                        pointerEvents: 'auto!important', // Allow pointer events on disabled items
                        backgroundColor: 'rgba(154, 32, 140, 0.2)',
                        ...dropdownMenuItemStyle
                    }}
                >
                    {`Select ${label}`}
                </MenuItem>
                {itemList && itemList.length > 0 && itemList.map((item, index) => {

                    const { isRead = false, isDisabled = false } = item;
                    const label = selectProperty === 'code'? item.value : item.label;
                    const value = item[selectProperty];
                    return (
                        <MenuItem
                            key={index}
                            value={value}
                            onClick={handleClickOnMenuItem ? () => handleClickOnMenuItem(value) : null}
                            disabled={isDisabled}
                            sx={{
                                cursor: isDisabled ? 'not-allowed!important' : 'pointer!important',
                                pointerEvents: isDisabled ? 'auto!important' : 'inherit!important', // Allow pointer events on disabled items
                                backgroundColor: isRead === true ? 'rgba(154, 32, 140, 0.2)' : 'inherit',
                                ...dropdownMenuItemStyle
                            }}
                        >
                            {label}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SelectInput